<template>
    <div>
        <div class="card">
            <div class="bg-blue-light px-1 rounded pb-2">
                <TitleButton
                        class="mt-1"
                        btnTitle="Go Back"
                        title="Edit Contra"
                        @onClickCloseButton="goToList"
                />

                <div class="row mt-1">
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Date</label>
                        <input
                            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                            tabindex="1"
                            type="date"
                            placeholder=""
                            v-model="contra.date"
                        >
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Voucher</label>
                        <div
                            class="input-group input-group-merge invoice-edit-input-group has-validation"
                            :class="{'is-invalid': isEmptyVoucher}"
                        >
                            <div class="input-group-text">
                                <span :class="{'text-danger': isEmptyVoucher}">{{ contra.voucher_prefix }}-</span>
                            </div>
                            <input
                                type="text"
                                class="form-control invoice-edit-input"
                                placeholder=""
                                aria-describedby="validationVoucherNameFeedback"
                                v-model="contra.voucher_serial"
                                :class="{'is-invalid': isEmptyVoucher}"
                            >
                        </div>
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-xl-3 col-form-label">Amount</label>
                        <input
                                v-model="amount"
                                @input="onInputAmount"
                                type="number"
                                class="form-control text-end"
                                placeholder="Amount"
                        >
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-xl-3 col-form-label">Cheque No</label>
                        <input
                                v-model="contra.payment_ref"
                                type="text"
                                class="form-control"
                                placeholder="Cheque No"
                        >
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Cheque date</label>
                        <input
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="date"
                                placeholder=""
                                v-model="contra.payment_ref_date"
                        >
                    </div>
                </div>
            </div>
            <div class="card p-2">
                <AddFormElement
                    class="mb-2"
                    :accountHeads="accountHeads"
                    :projects="projects"
                    :costCentres="costCentres"
                    :contactProfiles="contactProfiles"
                    :businesses="businesses"
                    v-for="(item, index) in contra.ledgers"
                    :key="index"
                    :index="index"
                    :item="item"
                />

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="note" class="form-label fw-bold">Memo</label>
                            <textarea v-model="contra.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="attachment" class="form-label fw-bold">Attachment</label>
                            <BlobFileViewer :attachments="contra.attachments" />
                            <FileUpload
                                    :is-drag-active="true"
                                    button-text="Upload Documents"
                                    v-model="contra.attachments"
                            >
                            </FileUpload>
                        </div>
                    </div>
                </div>

                <div class="mt-2 text-center">
                    <hr class="mb-1">
                    <button
                            :disabled="loading || isEmptyVoucher"
                            @click="updateContra"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Update
                    </button>
                    <button
                            @click="goToList"
                            class="btn btn-outline-secondary cancel-btn"
                    >
                        {{updateCounter === 0 ? 'Cancel' : 'Back'}}
                    </button>
                </div>
            </div>
        </div>
        <VoucherValidationModal
            ref="voucherValidationModal"
            :voucherQuery="voucherQuery"
            @on-press-ok="setVoucherNumberAndDate(true)"
        />
    </div>
</template>

<script setup>
import handleContra from '@/services/modules/contra'
import handleContact from '@/services/modules/contact'
import handleCBusinesses from '@/services/modules/businesses'
import {ref, inject, computed, watch, onMounted} from 'vue'

import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/ngo/contra/AddFormElement'
import {editVoucherNumber, generateQuery, generateVoucherNumber} from "@/services/utils/voucherNumberGenerator";
import {useRoute, useRouter} from "vue-router";
import handleProjects from "@/services/modules/procurement/project";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import BlobFileViewer from "@/components/atom/BlobFileViewer";
import FileUpload from "@/components/atom/FileUpload";
import VoucherValidationModal from "@/components/molecule/company/voucher/VoucherValidationModal.vue";
import figureFormatter from "@/services/utils/figureFormatter";

const $route = useRoute();
const $router = useRouter();
const showError =  inject('showError');
const showSuccess =  inject('showSuccess');

const {fetchAccountHeads, editContra, fetchSingleContra, loading} = handleContra();
const {fetchContactProfiles} = handleContact();
const {fetchBusinessList} = handleCBusinesses();
const {fetchProjects} = handleProjects()
const {fetchCostCentreList} = handleCostCentres()
const {decimalFormat} = figureFormatter();

const updateCounter = ref(0);
const amount = ref('');
const accountHeads = ref([]);
const contactProfiles = ref([]);
const businesses = ref([]);
const projects = ref([]);
const costCentres = ref([]);
const saveNewLoader = ref(false);
const isEmptyVoucher = ref(false);
const voucherValidationModal = ref(null);
const contra = ref({
    voucher_prefix : '',
    voucher_serial : '',
    voucher_no : '',
    company_id:'',
    contact_profile_id: null,
    business_id: null,
    date: '',
    payment_ref: '',
    payment_ref_date: '',
    note: '',
    mop_references: 'mop',
    voucher_type: 'contra_voucher',
    ledgers: []
});

const isLoading = computed( () => loading.value || saveNewLoader.value);
const start = computed( () => $route.query.start);
const end = computed( () => $route.query.end);
const general = computed( () => contra.value.ledgers);
const contraVoucherPrefix = computed(() => contra.value.voucher_prefix);
const contraVoucherSerial = computed(() => contra.value.voucher_serial);

const voucherQuery = computed(() => {
    return generateQuery($route.params.companyId, 'contra_voucher', 'general_contra')
});

//Watchers
watch( contraVoucherPrefix, () => {
    concatVoucher();
})

watch( contraVoucherSerial, () => {
    concatVoucher();
})

//methods
const concatVoucher = () => {
    isEmptyVoucher.value = false;

    if(contra.value.voucher_serial === '') {
        isEmptyVoucher.value = true;
        return ;
    }

    let voucherSerial = decimalFormat(contra.value.voucher_serial.toString().replace(/[^0-9\.]+/g, ''))
    if(voucherSerial === '') {
        isEmptyVoucher.value = true;
    }
    contra.value.voucher_serial = voucherSerial;
    contra.value.voucher_no = `${contra.value.voucher_prefix}-${voucherSerial}`;
}

const onInputAmount = ()  => {
    contra.value.ledgers.find(i => i.credit != null).credit = amount.value;
    contra.value.ledgers.find(i => i.debit != null).debit = amount.value;
}

const setData = () => {
    contra.value.ledgers.sort((a, b) => {
        if(a.credit == null) return 1
        if(b.debit == null) return -1
    })
    amount.value = contra.value.ledgers.find(i => i.credit !=null).credit
}

const goToList = () => {
    let params = {
        companyId: $route.params.companyId,
        moduleId: $route.params.moduleId,
        menuId: $route.params.menuId,
        pageId: $route.params.pageId
    }
    let query = {
        start: start.value,
        end: end.value
    }

    let isAllVouchers = $route.query.type && true;
    $router.push({
        query: query,
        name: isAllVouchers ? 'voucher-list' : 'np-contra-voucher-list',
        params: params
    })
}

const getFormData = () => {
    let formData = new FormData();
    let previous_file_ids = [];
    contra.value._method = 'PATCH'
    contra.value.company_id = $route.params.companyId
    if(!contra.value.contact_profile_id) contra.value.contact_profile_id = ''
    if(!contra.value.business_id) contra.value.business_id = ''
    Object.keys(contra.value).forEach(i => {
        if(i !== 'ledgers' && i !== 'attachments') {
            formData.append(i, contra.value[i])
        }
    })
    let ledger = Object.assign([], general.value);
    ledger[0].debit = null;
    ledger[0].mop_account_head_id = ledger[1].account_head_id;
    ledger[1].credit = null;
    ledger[1].mop_account_head_id = ledger[0].account_head_id;
    formData.append('general_ledgers', JSON.stringify(ledger))
    if(contra.value.attachments) {
        for(let i=0; i < contra.value.attachments.length; i++) {
            if(typeof contra.value.attachments[i] === 'object' && contra.value.attachments[i].hasOwnProperty('id')) {
                previous_file_ids.push(contra.value.attachments[i].id)
            } else {
                let key = `attachments[${i}]`;
                let value = contra.value.attachments[i];
                formData.append(key, value);
            }
        }
        if(previous_file_ids.length) {
            formData.append('previous_file_ids', JSON.stringify(previous_file_ids))
        }
    }
    return formData;
}

const setVoucherNumberAndDate = (withoutDate = false) => {
    new Promise(async (resolve, reject) => {
        try {
            if (!withoutDate) {
                contra.value.date = new Date().toISOString().split('T')[0]
            }
            let voucher = await generateVoucherNumber(voucherQuery.value);
            contra.value.voucher_prefix = voucher.prefix;
            contra.value.voucher_serial = voucher.serial;
            resolve();
        } catch (err) {
            reject(err);
        }
    })
}

const updateContra = async () => {
    concatVoucher();
    let data = getFormData();
    loading.value = true

    try {
        let res = await editContra(contra.value.id, data)
        if (!res.status) {
            showError(res.message)
        }
        if (res.status) {
            updateCounter.value++
            showSuccess(res.message)
            setTimeout(() => {
                goToList()
            }, 1500)
        }
    } catch (err) {
        if(!err.response) {
            showError('Something is wrong. Check your connectivity!!')
            return ;
        }

        if(err.response && err.response.status !== 406) {
            showError(err.response?.data.message)
        }
        if(err.response.data.data && err.response.data.data.show_modal) {
            voucherValidationModal.value.openModal(err.response?.data.message)
        }
    } finally {
        loading.value = false
        saveNewLoader.value = false
    }
}

onMounted( () => {
    loading.value = true
    let companyId = $route.params.companyId;
    let contactId = '';
    let q = '';
    let companyQuery = '?company_id=' + companyId;
    let projectQuery = companyQuery + '&with_donors=1';
    contra.value.date = new Date().toISOString().split('T')[0];
    contra.value.company_id = companyId;
    try {
        Promise.all([
            fetchAccountHeads(companyId)
                .then(res => {
                    if (res.data) {
                        accountHeads.value = res.data
                    }
                }),
            fetchContactProfiles(companyQuery)
                .then(res => {
                    if (res.data) {
                        contactProfiles.value = res.data;
                    }
                }),
            fetchProjects(projectQuery)
                .then(res => {
                    if (res.data) {
                        projects.value = res.data;
                    }
                }),
            fetchCostCentreList(companyQuery).then(res => {
                if (res.data) {
                    costCentres.value = res.data
                }
            }),
            fetchBusinessList(companyQuery).then(res => {
              if(res.data) {
                businesses.value = res.data;
              }
            }),
            fetchSingleContra($route.params.contraId).then( async res => {
                if (!res.status) {
                    showError(res.message)
                }
                if (res.status) {
                    contra.value = res.data
                    let voucher = editVoucherNumber(contra.value.voucher_no);
                    contra.value.voucher_prefix = voucher.prefix;
                    contra.value.voucher_serial = voucher.serial;
                    await setData()
                    // onSelectProject();
                }
            })
        ])
    } catch (e) {
        showError('Something went wrong please try again')
    } finally {
        loading.value = false
    }
});
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
    @media screen and (min-width: 1200px) {
    .btn-close {
        /* margin-left: 200px; */
        /* font-size: 32px; */
        /* margin-top: 5px; */
        width: 30px;
        height: 30px;
        background-color: #E4E6EB;
        border-radius: 50%;
        /* margin-right: 30px; */
    }
    .btn-close:hover {
        width: 30px;
        height: 30px;
        background-color: #D8DADF;
        border-radius: 50%;
    }
    }
    @media screen and (max-width: 720px) {
    .btn-close {
        width: 25px;
        height: 25px;
        background-color: #E4E6EB;
        border-radius: 50%;
        margin-top: -5px;
        margin-right: -5px;
    }
    }
</style>
